import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { updateSavedSign } from '../../../reducers/bookingEstimation';
import { URLS } from '../../../utils/urls';
import Button from '../../atoms/Button';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import Icon from '../../atoms/Icon';
import useCheckPC from '../../../hooks/useCheckPC';
import { getModusignEmbedLink, getTemplateDocuments, getRequestWithTemplate, getModusignContractDraft, getEmbeddedViewUrl } from '../../../lib/SignatureApi';
import { getBusinessCodeFormat, getDdEstimateCookieDataAndDecrypt, sleep } from '../../../lib/Util';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';
import {
  ContractPeriodOptions,
  DriverDispatchProducts,
  GenderOption,
  DriverSmokingStatusOption,
  DriverParkingSupportOption,
  CommutingVehicleProvidedOption,
  ForeignLanguageProficiencyOption,
  DriverWaitingRoomAvaliableOption,
  EducationalBackgroundOption,
  UsageTimeOptionsDriverDispatch
} from '../../../utils/EstimateFormData';
import { transFormWon } from '../../../utils/numbers';
import moment from 'moment-timezone';
import { getValueByTarget } from '../../../utils/parse';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import Modal from '../../molecules/Modal';
import Modal2 from '../../molecules/Modal2';
import { SetDriverDispatchEstimateJoinMember } from '../../../lib/DriverDispatchApi';
import { SetSendMailContractCompleteDriverDispatch } from '../../../lib/MailApi';
import sha1 from 'sha1'
import Modal3 from '../../molecules/Modal3';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';
import { SendSlackDdContractComplete } from '../../../lib/SlackApi';
import { SetMmEstimateHistory } from '../../../lib/EstimateApi';


/**
 * 계약서 확인 > 계약서 확인 페이지
 * name : ConfirmContract
 * */

const ConfirmContract = () => {
  const { isPC } = useCheckPC();

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [description, setDescription] = useState([]);
  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const [file, setFile] = useState(selectedOptions.userInformation.user.businessRegistration ?? null);
  const isExistsImages = useMemo(() => file?.length > 0, [file?.length]);
  const { BookingOption, DriverDispatchOption } = useSelector((state) => state);
  const [iframeUrl, setIframeUrl] = useState('')
  const [signatureIframeUrl, setSignatureIframeUrl] = useState('')
  const [isModal, setModal] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')
  const [containerWidth, setContainerWidth] = useState(0)

  const unmount = () => {
    if (isPC) {
      document.getElementById('bookingboxId').style.paddingLeft = 50 + 'px';
      document.getElementById('bookingboxId').style.paddingRight = 50 + 'px';
    }
    else {
      document.getElementById('bookingboxId').style.paddingLeft = 25 + 'px';
      document.getElementById('bookingboxId').style.paddingRight = 25 + 'px';
    }

    localStorage.removeItem('estimatedd_img')
    localStorage.removeItem('estimatedd_img_processed')
  }

  
  const unmount2 = () => {
    if (isPC) {
      
    }
    else {
      let root = document.getElementById('root')
      let iframeDoc = document.getElementById('iframe')
      let bookingBoxDoc = document.getElementById('bookingboxId')
      let steplistDoc = document.getElementById('step-list')
      
          
      

          
      bookingBoxDoc.style.marginBottom = '300px'
      iframeDoc.style.transform = ''
      iframeDoc.style.transformOrigin = ''
      iframeDoc.style.width = '100%'
      iframeDoc.style.height = '100%'
      root.style.overflowX = ''   
      steplistDoc.style.width = ''
    }
  }


  useEffect(() => {
    return () => unmount()
  }, [])

  useEffect(() => {
    const updatedDescription = [
      `${file ? '서명이 등록되었습니다.' : '서명이 등록되지 않았습니다.'}`,
    ]
    setDescription(updatedDescription)
  }, [
    file,
  ])


  const messageHandler = async (event) => {

    try {
      // 이벤트 실패
      if (event.data && event.data.indexOf('REDIRECT||REJECT') >= 0) {
        alert('계약을 거절 하셨습니다.')

        localStorage.removeItem('SignatureDdDocumentId')
        localStorage.removeItem('SignatureDdPartcipantsId')
        localStorage.removeItem('SignatureDdUrl')
        localStorage.removeItem('SignatureDdExpiredDate')

        setModal(false)
        setSignatureIframeUrl('')
        setIframeUrl(previewUrl)
      }
      else if (event.data && event.data.indexOf('REDIRECT||SUCCESS') >= 0) {
        let cookieInfo = getDdEstimateCookieDataAndDecrypt()
        let UserJoin = {
          UserId: cookieInfo.EmailAddress,
          UserPw: sha1(cookieInfo.PhoneNumber),
          AccountType: 'email',
          CustomerName: DriverDispatchOption?.userInformation?.user?.name,
          Email: cookieInfo.EmailAddress,
          PhoneNumber: cookieInfo.PhoneNumber,
          HomeAddress: (DriverDispatchOption?.userInformation?.userType === 'personal') ?
            DriverDispatchOption?.userInformation?.user?.personalAddress : DriverDispatchOption?.userInformation?.user?.businessAddress?.address1,
          UseType: 2,
          Roles: 0,
          CustomerCategory: 6,
          CustomertProductType: 2,
          MmPaymentYn: 'N',
        }

        let DriverDispatch = {
          ProductType: getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'ProductType'),
          ContractDate: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy-MM-DD'),
          OptionHex: DriverDispatchOption?.optionHex,
          GarageAddress: DriverDispatchOption?.basicInfo2?.garageAddress,
          CompanyAddress: DriverDispatchOption?.basicInfo2?.companyAddress,
          WorkStartDt: DriverDispatchOption?.basicInfo1?.workStartTime,
          WorkEndDt: DriverDispatchOption?.basicInfo1?.workEndTime
        }

        // 사업자 등록번호 정보는 개인일 경우 들어가지 않도록 처리할 필요가 있음.
        let BusinessLicense = {
          Address1: BookingOption?.userInformation?.user?.businessAddress?.address1,
          Address2: "",
          BusinessCode: BookingOption?.userInformation?.user?.businessRegistrationId,
          ImageUrl: "www.naver.com",
          RepresentativeName: BookingOption?.userInformation?.user?.name,
        }

        let sendObj = {
          UserJoin,
          DriverDispatch,
          BusinessLicense: (BookingOption?.userInformation?.userType === 'personal') ? null : BusinessLicense
        }


        SetMmEstimateHistory({
          ...getDdEstimateCookieDataAndDecrypt(),
          EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
          EstimatePage: PAGE.PAGE_09,
          JsonData: JSON.stringify(DriverDispatchOption)
        })

        let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}${getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'title')}`
        let obj = {
          UserType: (DriverDispatchOption?.userInformation?.userType === 'personal') ? '개인' : '법인',
          ContractName: DriverDispatchOption?.userInformation?.user?.name,
          ContractProduct: ProductStr
        }
        await SendSlackDdContractComplete(obj)
        // 당분간 기능 죽여놓았음
        // await SetDriverDispatchEstimateJoinMember(sendObj)
        // await SetSendMailContractCompleteDriverDispatch(UserJoin)


        alert('계약이 완료 되었습니다.')

        localStorage.removeItem('SignatureDdDocumentId')
        localStorage.removeItem('SignatureDdPartcipantsId')
        localStorage.removeItem('SignatureDdUrl')
        localStorage.removeItem('SignatureDdExpiredDate')

        setModal(false)
        setSignatureIframeUrl('')
        navigate(URLS.DRIVER_DISPATCH_COMPLETE_CONTRACT)
      }
      // else {
      //   alert('계약 도중 문제가 발생했습니다.')
      //   setModal(false)
      //   setSignatureIframeUrl('')
      // }
    }
    catch (e) {
      // alert('계약 도중 문제가 발생했습니다.(2)')
      // setModal(false)
      // setSignatureIframeUrl('')
    }


  }

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('message', messageHandler);
    };

  }, [])


  const getChkAge = (data) => {
    return data ? 'O' : 'X'
  }


  useEffect(() => {
    async function getData() {
      // let result = await getModusignEmbedLink()
      // let result = await getTemplateDocuments()
      //let result = await getRequestWithTemplate()
      dispatch(openLoading())

      let dataurlLoading = null
      while (true) {
        // console.log('while statement')
        await sleep(1000)
        dataurlLoading = localStorage.getItem('estimatedd_img_processed')
        if (dataurlLoading !== null && dataurlLoading === 'false') {
          localStorage.setItem('estimatedd_img_processed', true)
          break;
        }
      }

      dataurlLoading = localStorage.getItem('estimatedd_img_processed')
      if (dataurlLoading === 'true') {

        let userType = DriverDispatchOption?.userInformation?.userType;
        let RecruitInfo = {
          workStartDate: DriverDispatchOption?.recruitRequest?.workStartDate,
          gender: getValueByTarget(GenderOption, DriverDispatchOption?.recruitRequest?.gender, 'title'),
          age20: getChkAge(DriverDispatchOption?.recruitRequest?.age20),
          age30: getChkAge(DriverDispatchOption?.recruitRequest?.age30),
          age40: getChkAge(DriverDispatchOption?.recruitRequest?.age40),
          age50: getChkAge(DriverDispatchOption?.recruitRequest?.age50),
          age60: getChkAge(DriverDispatchOption?.recruitRequest?.age60),
          ageboth: getChkAge(DriverDispatchOption?.recruitRequest?.ageboth),
          driverSmokingStatus: getValueByTarget(DriverSmokingStatusOption, DriverDispatchOption?.recruitRequest?.driverSmokingStatus, 'title'),
          driverParkingSupport: getValueByTarget(DriverParkingSupportOption, DriverDispatchOption?.recruitRequest?.driverParkingSupport, 'title'),
          commutingVehicleProvided: getValueByTarget(CommutingVehicleProvidedOption, DriverDispatchOption?.recruitRequest?.commutingVehicleProvided, 'title'),
          postHireJobResponsibilities: DriverDispatchOption?.recruitRequest?.postHireJobResponsibilities,
          benefits: DriverDispatchOption?.recruitRequest?.benefits,
          vehicleModelName: DriverDispatchOption?.recruitRequest?.vehicleModelName,
          foreignLanguageProficiency: getValueByTarget(ForeignLanguageProficiencyOption, DriverDispatchOption?.recruitRequest?.foreignLanguageProficiency, 'title'),
          foreignLanguageProficiencyStr: DriverDispatchOption?.recruitRequest?.foreignLanguageProficiencyStr,
          driverWaitingRoomAvailable: getValueByTarget(DriverWaitingRoomAvaliableOption, DriverDispatchOption?.recruitRequest?.driverWaitingRoomAvailable, 'title'),
          actualWorkLocation: DriverDispatchOption?.basicInfo2?.companyAddress,
          educationalBackground: getValueByTarget(EducationalBackgroundOption, DriverDispatchOption?.recruitRequest?.educationalBackground, 'title'),
          specialNotes: DriverDispatchOption?.recruitRequest?.specialNotes ?? ''
        }

        // 모두싸인 데이터 초기화
        let EstimateInfo = {
          CompanyName: userType === 'personal' ? DriverDispatchOption?.userInformation?.user?.name : DriverDispatchOption?.userInformation?.user?.companyName,
          Address:
            userType === 'personal' ?
              DriverDispatchOption?.userInformation?.user?.personalAddress + ' ' + DriverDispatchOption?.userInformation?.user?.personalAddressDetail :
              DriverDispatchOption?.userInformation?.user?.businessAddress?.address1 + ' ' + DriverDispatchOption?.userInformation?.user?.businessAddress?.address2,
          Name: DriverDispatchOption?.userInformation?.user?.name,
          Month: moment().format('MM'),
          Day: moment().format('DD'),
          ContractDate: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일'),
          BusinessCode: userType === 'personal' ? '' : getBusinessCodeFormat(DriverDispatchOption?.userInformation?.user?.businessRegistrationId),
          EstimateImage: localStorage.getItem('estimatedd_img'),
          RecruitInfo
        }

        let recruitInfoStr =
          `출근일 : ${RecruitInfo.workStartDate}

성별 : ${RecruitInfo.gender}

연령 : 20대 (${RecruitInfo.age20}) 30대 (${RecruitInfo.age30}) 40대 (${RecruitInfo.age40}) 50대 (${RecruitInfo.age50}) 60대 (${RecruitInfo.age60}) 무관 (${RecruitInfo.ageboth})

기사 흡연 여부 : ${RecruitInfo.driverSmokingStatus}

출퇴근 차량 제공 : ${RecruitInfo.commutingVehicleProvided}
${RecruitInfo.commutingVehicleProvided === '불가능' && `기사 주차지원 : ${RecruitInfo.driverParkingSupport}`}

기사 대기실 유무 : ${RecruitInfo.driverWaitingRoomAvailable}

학력 : ${RecruitInfo.educationalBackground}

입사 후 담당업무 : ${RecruitInfo.postHireJobResponsibilities}

복리후생 : ${RecruitInfo.benefits}

차량 모델명 : ${RecruitInfo.vehicleModelName}

외국어 가능여부 : ${RecruitInfo.foreignLanguageProficiency}
${RecruitInfo.foreignLanguageProficiency === '필요' && `${RecruitInfo.foreignLanguageProficiencyStr}`}

특이사항 : ${RecruitInfo.specialNotes}

※포터가 작업하는 최종안이 나오면 대체 하겠습니다.`




        let modusignTemplateInfo = {}
        // 파견 상품을 선택시
        if (DriverDispatchOption?.subscription === 'dispatch') {
          modusignTemplateInfo = {
            templateId: "1293b900-7789-11ee-9536-6ddeefec66ce",
            document: {
              participantMappings: [
                {
                  excluded: false,
                  signingMethod: {
                    type: "SECURE_LINK",
                    // value: cookieObj.EmailAddress
                    value: 'rise@mosiler.com'
                  },
                  signingDuration: 20160,
                  locale: "ko",
                  name: EstimateInfo.CompanyName,
                  role: "고객"
                }
              ],
              requesterInputMappings: [
                { dataLabel: "CompanyName1", value: EstimateInfo.CompanyName },
                { dataLabel: "CompanyName2", value: EstimateInfo.CompanyName },
                { dataLabel: "StartDate", value: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일') },
                { dataLabel: "NowDate", value: moment().format('yyyy년 MM월 DD일') },
                { dataLabel: "BusinessCode", value: EstimateInfo.BusinessCode },
                { dataLabel: "Address", value: EstimateInfo.Address },
                { dataLabel: "Name1", value: userType === 'personal' ? '' : EstimateInfo.CompanyName },
                { dataLabel: "Name2", value: EstimateInfo.Name },
                { dataLabel: "Req_Name", value: EstimateInfo.Name + ' 고객님' },
                { dataLabel: "Req_Body", value: recruitInfoStr },
                { dataLabel: "Image2", value: { base64: EstimateInfo.EstimateImage } },
              ],
              title: "2023 서명테스트"
            },
          }
        }
        // 채용상품 선택시
        else {
          modusignTemplateInfo = {
            templateId: "a21192c0-7f61-11ee-8a55-1f4a5aaa46c0",
            document: {
              participantMappings: [
                {
                  excluded: false,
                  signingMethod: {
                    type: "SECURE_LINK",
                    // value: cookieObj.EmailAddress
                    value: 'rise@mosiler.com'
                  },
                  signingDuration: 20160,
                  locale: "ko",
                  name: EstimateInfo.CompanyName,
                  role: "고객"
                }
              ],
              requesterInputMappings: [
                { dataLabel: "CompanyName1", value: EstimateInfo.CompanyName },
                { dataLabel: "CompanyName2", value: EstimateInfo.CompanyName },
                { dataLabel: "StartDate", value: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일') },
                { dataLabel: "NowDate", value: moment().format('yyyy년 MM월 DD일') },
                { dataLabel: "BusinessCode", value: EstimateInfo.BusinessCode },
                { dataLabel: "Address", value: EstimateInfo.Address },
                { dataLabel: "Name1", value: userType === 'personal' ? '' : EstimateInfo.CompanyName },
                { dataLabel: "Name2", value: EstimateInfo.Name },
                { dataLabel: "Image2", value: { base64: EstimateInfo.EstimateImage } },
              ],
              title: "2023 서명테스트"
            },
          }
        }

        let result = await getModusignContractDraft(modusignTemplateInfo)

        setIframeUrl(result.embeddedUrl + '&mode=preview')
        setPreviewUrl(result.embeddedUrl + '&mode=preview')

      }

      const getTransformStr = (viewport_width, left_padding, right_padding, scale_factor, iframe_width) => {
        
        //  실제 사용 가능한 너비 (padding을 제외한 너비)
        let available_width = viewport_width - left_padding - right_padding

        //  iframe이 축소된 후의 너비
        let scaled_iframe_width = iframe_width * scale_factor

        // # 가운데 정렬을 위해 필요한 translation (translateX)
        // # (사용 가능한 너비 - 축소된 iframe 너비) / 2
        let translate_x = (available_width - scaled_iframe_width) / 2

        // # translateY는 0이 될 것이므로, scale에 영향을 주지 않음
        let translate_y = 0

        // # translateX 값을 퍼센테이지로 변환하기 위해 전체 뷰포트 너비 대비 계산
        let translate_x_percentage = (translate_x / viewport_width) * 100

        // # translateY 값도 퍼센테이지로 변환 (여기서는 0이므로 0%)
        let translate_y_percentage = 0

        return `translate(${translate_x_percentage}%, ${translate_y_percentage}%) scale(${scale_factor})` 
      }




      // 모바일 이라면 양옆 패딩을 줄여서 계약서가 한번에 보이도록 세팅
      if (!isPC) {
        let containerWidth = document.getElementById('bookingboxId').offsetWidth
        setContainerWidth(containerWidth)
        let contentWidth = document.getElementById('iframe').offsetWidth
        let bookingBoxDoc = document.getElementById('bookingboxId')


        if (containerWidth < 380) {
          let root = document.getElementById('root')
          let iframeDoc = document.getElementById('iframe')
          let steplistDoc = document.getElementById('step-list')
      
          // iframe의 퍼센트를 80%로 낮추고
          iframeDoc.style.transform = getTransformStr(containerWidth, 25, 25, 0.8, 400)
          iframeDoc.style.transformOrigin = 'left top'
          // iframe의 width를 380으로 설정
          iframeDoc.style.width = '400px'
          iframeDoc.style.height = '600px'
          root.style.overflowX = 'hidden'
          steplistDoc.style.width = containerWidth-50 + 'px'
          
        }
        else {
          let padding = (containerWidth - 380) / 2; // 양쪽에 동일한 패딩을 설정하기 위해 2로 나눕니다.

          document.getElementById('bookingboxId').style.paddingLeft = padding + 'px';
          document.getElementById('bookingboxId').style.paddingRight = padding + 'px';
  
          // iframe은 각각 별도의 URL이기 때문에 Cross-origin 문제로 인해 접근이 불가
          // 따라서 스크롤바도 없애지 못함
          // 이거 관련해서 없애달라고 얘기해도 모두싸인 답변은 못한다 일듯.
          // 전에 + 버튼, - 버튼을 이쪽에서 수정할 수 있도록 요청했지만 거절했음.
        }

        bookingBoxDoc.style.marginBottom = '150px'
      }

      dispatch(closeLoading())




    }
    getData()
  }, [])


  const {
    handleSubmit, setValue, register, setError, formState: { errors } } = useForm({
      defaultValues: {
        savedSign: selectedOptions?.savedSign,
      }
    });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;



    if (validCheck) {
      setSelectedOptions((prevState) => ({
        ...prevState,
        savedSign: data?.savedSign,
      }));
      dispatch(updateSavedSign({
        savedSign: data?.savedSign ?? selectedOptions?.savedSign,
      }))
      navigate(URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT)
    }
  })

  const handleImages = (e) => {
    const target = {
      images: Array.from(e.target.files),
    };
    if (target) {
      setFile(target);
      setError('savedSign', null);
      setValue('savedSign', target)
    }
  };



  const SignatureModal = () => {
    const pdfDown = localStorage.getItem('SignatureDdPdfDownloadUrl')
    return (
      <Modal3
        title={'서명하기'}
        style={{}}
      >
        <div className={classNames('main-set')}>

          <iframe
            id='iframe2'
            src={signatureIframeUrl}
            style={{ width: '100%', height: '650px', border: '1px solid #a5a5a5', transform: 'scale(1)' }} />
        </div>
        <div className={classNames('button-set')}>

          {pdfDown?.length && (<Button
            // styleType={'secondary'}
            onClick={async () => {
              window.open(pdfDown, '_blank')
            }}
          >
            PDF 다운로드
          </Button>)}
          <Button
            // styleType={'secondary'}
            onClick={async () => {
              setModal(false)
              setSignatureIframeUrl('')
              setIframeUrl(previewUrl)
              if (!isPC) {
                let padding = (containerWidth - 380) / 2; // 양쪽에 동일한 패딩을 설정하기 위해 2로 나눕니다.

                document.getElementById('bookingboxId').style.paddingLeft = padding + 'px';
                document.getElementById('bookingboxId').style.paddingRight = padding + 'px';


              }




              /*
              // 모바일 이라면 양옆 패딩을 줄여서 계약서가 한번에 보이도록 세팅
      if (!isPC) {
        let containerWidth = document.getElementById('bookingboxId').offsetWidth
        let contentWidth = document.getElementById('iframe').offsetWidth

        let padding = (containerWidth - 380) / 2; // 양쪽에 동일한 패딩을 설정하기 위해 2로 나눕니다.

        document.getElementById('bookingboxId').style.paddingLeft = padding + 'px';
        document.getElementById('bookingboxId').style.paddingRight = padding + 'px';

        // iframe은 각각 별도의 URL이기 때문에 Cross-origin 문제로 인해 접근이 불가
        // 따라서 스크롤바도 없애지 못함
        // 이거 관련해서 없애달라고 얘기해도 모두싸인 답변은 못한다 일듯.
        // 전에 + 버튼, - 버튼을 이쪽에서 수정할 수 있도록 요청했지만 거절했음.
      }
              */

            }}
          >
            닫기
          </Button>
        </div>

      </Modal3>
    )
  }

  const handleContractDocView = async () => {
    // 로딩
    dispatch(openLoading())
    let SignatureUrl = localStorage.getItem('SignatureDdUrl')
    let SignatureExpiredDate = localStorage.getItem('SignatureDdExpiredDate')


    let userType = DriverDispatchOption?.userInformation?.userType;

    let RecruitInfo = {
      workStartDate: DriverDispatchOption?.recruitRequest?.workStartDate,
      gender: getValueByTarget(GenderOption, DriverDispatchOption?.recruitRequest?.gender, 'title'),
      age20: getChkAge(DriverDispatchOption?.recruitRequest?.age20),
      age30: getChkAge(DriverDispatchOption?.recruitRequest?.age30),
      age40: getChkAge(DriverDispatchOption?.recruitRequest?.age40),
      age50: getChkAge(DriverDispatchOption?.recruitRequest?.age50),
      age60: getChkAge(DriverDispatchOption?.recruitRequest?.age60),
      ageboth: getChkAge(DriverDispatchOption?.recruitRequest?.ageboth),
      driverSmokingStatus: getValueByTarget(DriverSmokingStatusOption, DriverDispatchOption?.recruitRequest?.driverSmokingStatus, 'title'),
      driverParkingSupport: getValueByTarget(DriverParkingSupportOption, DriverDispatchOption?.recruitRequest?.driverParkingSupport, 'title'),
      commutingVehicleProvided: getValueByTarget(CommutingVehicleProvidedOption, DriverDispatchOption?.recruitRequest?.commutingVehicleProvided, 'title'),
      postHireJobResponsibilities: DriverDispatchOption?.recruitRequest?.postHireJobResponsibilities,
      benefits: DriverDispatchOption?.recruitRequest?.benefits,
      vehicleModelName: DriverDispatchOption?.recruitRequest?.vehicleModelName,
      foreignLanguageProficiency: getValueByTarget(ForeignLanguageProficiencyOption, DriverDispatchOption?.recruitRequest?.foreignLanguageProficiency, 'title'),
      foreignLanguageProficiencyStr: DriverDispatchOption?.recruitRequest?.foreignLanguageProficiencyStr,
      driverWaitingRoomAvailable: getValueByTarget(DriverWaitingRoomAvaliableOption, DriverDispatchOption?.recruitRequest?.driverWaitingRoomAvailable, 'title'),
      actualWorkLocation: DriverDispatchOption?.basicInfo2?.companyAddress,
      educationalBackground: getValueByTarget(EducationalBackgroundOption, DriverDispatchOption?.recruitRequest?.educationalBackground, 'title'),
      specialNotes: DriverDispatchOption?.recruitRequest?.specialNotes ?? ''
    }

    // 모두싸인 데이터 초기화
    let EstimateInfo = {
      CompanyName: userType === 'personal' ? DriverDispatchOption?.userInformation?.user?.name : DriverDispatchOption?.userInformation?.user?.companyName,
      Address:
        userType === 'personal' ?
          DriverDispatchOption?.userInformation?.user?.personalAddress + ' ' + DriverDispatchOption?.userInformation?.user?.personalAddressDetail :
          DriverDispatchOption?.userInformation?.user?.businessAddress?.address1 + ' ' + DriverDispatchOption?.userInformation?.user?.businessAddress?.address2,
      Name: DriverDispatchOption?.userInformation?.user?.name,
      Month: moment().format('MM'),
      Day: moment().format('DD'),
      ContractDate: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일'),
      BusinessCode: userType === 'personal' ? '' : getBusinessCodeFormat(DriverDispatchOption?.userInformation?.user?.businessRegistrationId),
      EstimateImage: localStorage.getItem('estimatedd_img'),
      RecruitInfo
    }

    let recruitInfoStr =
      `출근일 : ${RecruitInfo.workStartDate}

성별 : ${RecruitInfo.gender}

연령 : 20대 (${RecruitInfo.age20}) 30대 (${RecruitInfo.age30}) 40대 (${RecruitInfo.age40}) 50대 (${RecruitInfo.age50}) 60대 (${RecruitInfo.age60}) 무관 (${RecruitInfo.ageboth})

기사 흡연 여부 : ${RecruitInfo.driverSmokingStatus}

출퇴근 차량 제공 : ${RecruitInfo.commutingVehicleProvided}
${RecruitInfo.commutingVehicleProvided === '불가능' && `기사 주차지원 : ${RecruitInfo.driverParkingSupport}`}

기사 대기실 유무 : ${RecruitInfo.driverWaitingRoomAvailable}

학력 : ${RecruitInfo.educationalBackground}

입사 후 담당업무 : ${RecruitInfo.postHireJobResponsibilities}

복리후생 : ${RecruitInfo.benefits}

차량 모델명 : ${RecruitInfo.vehicleModelName}

외국어 가능여부 : ${RecruitInfo.foreignLanguageProficiency}
${RecruitInfo.foreignLanguageProficiency === '필요' && `${RecruitInfo.foreignLanguageProficiencyStr}`}

특이사항 : ${RecruitInfo.specialNotes}

※포터가 작업하는 최종안이 나오면 대체 하겠습니다.`

    // let cookieObj = getEstimateCookieDataAndDecrypt()
    let cookieInfo = getDdEstimateCookieDataAndDecrypt()

    let reqObj = {}
    // 파견 상품을 선택시
    if (DriverDispatchOption?.subscription === 'dispatch') {
      reqObj = {
        templateId: "1293b900-7789-11ee-9536-6ddeefec66ce",
        document: {
          participantMappings: [
            {
              excluded: false,
              signingMethod: {
                type: "SECURE_LINK",
                value: cookieInfo.EmailAddress
                // value: 'rise@mosiler.com'
              },
              signingDuration: 20160,
              locale: "ko",
              name: EstimateInfo.CompanyName,
              role: "고객"
            }
          ],
          requesterInputMappings: [
            { dataLabel: "CompanyName1", value: EstimateInfo.CompanyName },
            { dataLabel: "CompanyName2", value: EstimateInfo.CompanyName },
            { dataLabel: "StartDate", value: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일') },
            { dataLabel: "NowDate", value: moment().format('yyyy년 MM월 DD일') },
            { dataLabel: "BusinessCode", value: EstimateInfo.BusinessCode },
            { dataLabel: "Address", value: EstimateInfo.Address },
            { dataLabel: "Name1", value: userType === 'personal' ? '' : EstimateInfo.CompanyName },
            { dataLabel: "Name2", value: EstimateInfo.Name },
            { dataLabel: "Req_Name", value: EstimateInfo.Name + ' 고객님' },
            { dataLabel: "Req_Body", value: recruitInfoStr },
            { dataLabel: "Image2", value: { base64: EstimateInfo.EstimateImage } },
          ],
          carbonCopies: [{
            contact: cookieInfo.EmailAddress
          }],
          title: `${moment().format('yyyyMMDD')}}_${EstimateInfo.CompanyName}_계약`
        },
      }
    }
    // title: `${moment().format('yyyyMMDD')}}_${EstimateInfo.CompanyName}_계약`
    // 채용상품 선택시
    else {
      reqObj = {
        templateId: "a21192c0-7f61-11ee-8a55-1f4a5aaa46c0",
        document: {
          participantMappings: [
            {
              excluded: false,
              signingMethod: {
                type: "SECURE_LINK",
                value: cookieInfo.EmailAddress
              },
              signingDuration: 20160,
              locale: "ko",
              name: EstimateInfo.CompanyName,
              role: "고객"
            }
          ],
          requesterInputMappings: [
            { dataLabel: "CompanyName1", value: EstimateInfo.CompanyName },
            { dataLabel: "CompanyName2", value: EstimateInfo.CompanyName },
            { dataLabel: "StartDate", value: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일') },
            { dataLabel: "NowDate", value: moment().format('yyyy년 MM월 DD일') },
            { dataLabel: "BusinessCode", value: EstimateInfo.BusinessCode },
            { dataLabel: "Address", value: EstimateInfo.Address },
            { dataLabel: "Name1", value: userType === 'personal' ? '' : EstimateInfo.CompanyName },
            { dataLabel: "Name2", value: EstimateInfo.Name },
            { dataLabel: "Image2", value: { base64: EstimateInfo.EstimateImage } },
          ],
          carbonCopies: [{
            contact: cookieInfo.EmailAddress
          }],
          title: `${moment().format('yyyyMMDD')}}_${EstimateInfo.CompanyName}_채용상품_계약`
        },
      }
    }

    let expired = ''
    let currentTime = moment().tz('Asia/Seoul')
    if (SignatureExpiredDate !== null) {
      expired = moment(SignatureExpiredDate).tz('Asia/Seoul')
    }


    if (SignatureUrl !== null || SignatureExpiredDate !== null) {
      if ((currentTime.diff(expired, 'minutes') >= 10)) {
        let result = await getRequestWithTemplate(reqObj)
        //let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://localhost:3000/booking/modu-redirect')
        let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://newestimate.mosiler.com/driverdispatch/modu-redirect')
        localStorage.setItem('SignatureDdDocumentId', result?.id)
        localStorage.setItem('SignatureDdPartcipantsId', result?.participants[0]?.id)
        localStorage.setItem('SignatureDdUrl', embeddedData.embeddedUrl)
        localStorage.setItem('SignatureDdExpiredDate', result?.createdAt)
        localStorage.setItem('SignatureDdPdfDownloadUrl', result?.file.downloadUrl)
        setSignatureIframeUrl(embeddedData.embeddedUrl)
        setModal(true)
        dispatch(closeLoading())
      }
      else {
        // 아래 값을 저장할 게 아니라 문서 ID, participants[0]?.id를 저장해야할 것.
        let docid = localStorage.getItem('SignatureDdDocumentId')
        let partid = localStorage.getItem('SignatureDdPartcipantsId')
        //let embeddedData = await getEmbeddedViewUrl(docid, partid, 'https://localhost:3000/booking/modu-redirect')
        let embeddedData = await getEmbeddedViewUrl(docid, partid, 'https://newestimate.mosiler.com/driverdispatch/modu-redirect')
        setSignatureIframeUrl(embeddedData.embeddedUrl)
        setModal(true)
        dispatch(closeLoading())
      }
    }
    else {
      let result = await getRequestWithTemplate(reqObj)
      let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://newestimate.mosiler.com/driverdispatch/modu-redirect')
      //let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://localhost:3000/booking/modu-redirect')
      localStorage.setItem('SignatureDdDocumentId', result?.id)
      localStorage.setItem('SignatureDdPartcipantsId', result?.participants[0]?.id)
      localStorage.setItem('SignatureDdUrl', embeddedData.embeddedUrl)
      localStorage.setItem('SignatureDdExpiredDate', result?.createdAt)
      localStorage.setItem('SignatureDdPdfDownloadUrl', result?.file?.downloadUrl)
      setSignatureIframeUrl(embeddedData.embeddedUrl)
      setModal(true)
      dispatch(closeLoading())
    }
    setIframeUrl('')

  }



  return (
    <>
      {isModal && SignatureModal()}
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'계약서 확인'} subtitle={'계약서를 확인해 주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          {/* <div className={classNames('booking-form-list')}> */}
          <div style={{ width: '100%' }}>
            <div className={'booking-form-item'}>
              <div className={classNames('contract-slide')} style={{}}>
                <iframe id="iframe" src={iframeUrl} scrolling='no' style={{ width: '100%', height: '100%', border: '1px solid #a5a5a5', overflowX: 'hidden', overflowY: 'auto', boxSizing: 'border-box' }} />
              </div>
              <div className={classNames('contract-confirm-controls')}>
                <div className={classNames('button-set')}>
                  {isPC && (
                    <>
                      <Button
                        styleType={'tertiary'}
                        onClick={handleContractDocView}>

                        계약하기
                      </Button>
                      <Button
                        styleType={'tertiary'}
                        onClick={async () => {
                          navigate(URLS.DRIVER_DISPATCH_USER_INFORMATION)
                        }}>

                        이전
                      </Button>
                    </>
                  )}


                </div>

              </div>
              {errors.savedSign && !file && (
                <span className={classNames('error')}>
                  {errors.savedSign.message}
                </span>
              )}
            </div>
          </div>

          {!isPC && (
            <BookingFormSummary
              hasAmonut={false}
              description={description}>
              <div className={classNames('button-set')}>
                <Button
                  onClick={
                    () => {
                      unmount2()
                      navigate(URLS.DRIVER_DISPATCH_USER_INFORMATION)
                    }
                  }
                >이전</Button>
                <Button
                  styleType={'secondary'}
                  onClick={handleContractDocView}
                >계약하기</Button>
              </div>
            </BookingFormSummary>
          )}


        </form>

        {/* DATAURL 정보를 localstorage에 저장한다. */}
        <div style={{ width: '1px', height: '1px', visibility:'hidden'}}>
          <iframe src={URLS.DRIVER_DISPATCH_ESTIMATE_DOWNLOAD_DATAURL} style={{ width: '1px', height: '1px', }} />
        </div>

      </div>
    </>
  )
}

export default ConfirmContract