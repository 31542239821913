import React, { useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { BookingProducts, ContractPeriodOptions, PurposeOfContractOptions, UsageTimeOptionsAnnual, UsageTimeOptionsMonthly, UserTypeOptions } from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import BookingItem from '../../molecules/BookingItem';
import Input from '../../atoms/Input';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { getValueByTarget } from '../../../utils/parse';
import { useForm } from 'react-hook-form';
import { updateBusinessLicenseImage, updateUserInformation } from '../../../reducers/bookingEstimation';
import AddressInput2 from '../../molecules/AddressInput2';
import Icon from '../../atoms/Icon';
import { formErrorMessage } from '../../../utils/alertMessage';
import { SetMmEstimateHistory, getEstimateHistoryInfo, setSheetContractResponse } from '../../../lib/EstimateApi';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { base64EncodeUnicode, getEstimateCookieData, getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { uploadFile } from '../../../lib/MsAzureApi';
import { openLoading, closeLoading } from '../.././../reducers/loadingRedux';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import { SendSlackContracting } from '../../../lib/SlackApi';
import { SendEztoc2 } from '../../../lib/EztocApi';
import AddressInput from '../../molecules/AddressInputBackup';
import DatePickerInput from '../../molecules/DatePickerInput';

/**
 * 계약서 확인 > 고객 정보 페이지
 * name : userInformation
 * */

const UserInformation = () => {
	const inputRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
	const [description, setDescription] = useState([]);

	const [file, setFile] = useState(selectedOptions.userInformation.user.businessRegistration ?? null);
	const isExistsImages = useMemo(() => file?.length > 0, [file?.length]);

	const [userType, setUserType] = useState(selectedOptions.userInformation.userType);
	const { BookingOption } = useSelector((state) => state);
	const [fileManage, setFileManage] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);

	useEffect(() => {
		const updatedDescription = [];
		updatedDescription.push(
			`<i class='blue'>
      ${getValueByTarget(UserTypeOptions, selectedOptions.userInformation.userType, 'title')}</i> 계약의 형태로 진행됩니다.`
		);
		if (userType !== 'personal') {
			updatedDescription.push(
				`계약 고객님의 성함은 <i class='blue'>${selectedOptions?.userInformation?.user?.name ?? '미입력'}</i> 입니다.`,
				`계약 고객님의 회사명은 <i class='blue'>${selectedOptions?.userInformation?.user?.companyName ?? '미입력'}</i> 입니다.`,
				`사업장의 주소는 <i class='blue'>${selectedOptions?.userInformation?.user?.businessAddress?.address1 ?? '미입력'} ${
					selectedOptions?.userInformation?.user?.businessAddress?.address2 ?? '미입력'
				} </i> 입니다.`,
				`사업자 등록번호는 <i class='blue'>${selectedOptions?.userInformation?.user?.businessRegistrationId ?? '미입력'}</i> 입니다.`,
				`사업자 등록증이 ${file ? '첨부 되었습니다.' : '첨부되지 않았습니다.'}`
			);
		} else {
			updatedDescription.push(
				`계약 고객님의 성함은 <i class='blue'>${selectedOptions?.userInformation?.user?.name ?? '미입력'}</i> 입니다.`,
				`고객님의 주소는 <i class='blue'>${selectedOptions?.userInformation?.user?.personalAddress ?? '미입력'} ${
					selectedOptions?.userInformation?.user?.personalAddressDetail ?? '미입력'
				} </i> 입니다.`
			);
		}
		setDescription(updatedDescription);
	}, [
		selectedOptions.userInformation.userType,
		selectedOptions.userInformation.user.name,
		selectedOptions.userInformation.user.businessAddress.address1,
		selectedOptions.userInformation.user.businessAddress.address2,
		selectedOptions.userInformation.user.businessRegistrationId,
		selectedOptions.userInformation.user.personalAddress,
		selectedOptions?.userInformation?.user?.personalAddressDetail,
		selectedOptions?.userInformation?.user?.companyName,
		file,
	]);

	const {
		handleSubmit,
		setValue,
		register,
		setError,
		getValues,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			userType: selectedOptions?.userInformation?.userType,
			name: selectedOptions?.userInformation?.user?.name,
			zip: selectedOptions?.userInformation?.user?.businessAddress?.zip,
			address1: selectedOptions?.userInformation?.user?.businessAddress?.address1,
			address2: selectedOptions?.userInformation?.user?.businessAddress?.address2,
			businessRegistrationId: selectedOptions?.userInformation?.user?.businessRegistrationId,
			businessRegistration: selectedOptions?.userInformation?.user?.businessRegistration,
			companyName: selectedOptions?.userInformation?.user?.companyName,
			personalAddress: selectedOptions?.userInformation?.user?.personalAddress,
			personalAddressDetail: selectedOptions?.userInformation?.user?.personalAddressDetail,
		},
	});

	const onFileUpload = async () => {
		// *** UPLOAD TO AZURE STORAGE ***
		const blobsInContainer = await uploadFile(fileManage);

		// reset state/form
		setFileManage(null);

		return blobsInContainer;
	};

	const onSubmit = handleSubmit(async (data) => {
		let validCheck = true;
		// console.log(isExistsImages, (file?.images?.length))

		if (!data?.name) {
			validCheck = false;
			setError(
				'name',
				{
					type: 'focus',
					name: 'name',
					message: formErrorMessage.nameError,
				},
				{ shouldFocus: true }
			);
			return;
		}

		if (data?.name?.length <= 1) {
			validCheck = false;
			setError(
				'name',
				{
					type: 'focus',
					name: 'name',
					message: '이름은 2자 이상 입니다.',
				},
				{ shouldFocus: true }
			);
		}

		if (userType !== 'personal') {
			if (!data?.companyName) {
				validCheck = false;
				setError(
					'companyName',
					{
						type: 'focus',
						name: 'companyName',
						message: formErrorMessage.companyNameError,
					},
					{ shouldFocus: true }
				);
			}

			if (!data?.address1) {
				validCheck = false;
				setError(
					'address1',
					{
						type: 'focus',
						name: 'address1',
						message: formErrorMessage.address1Error,
					},
					{ shouldFocus: true }
				);
			}

			if (!data?.address2) {
				validCheck = false;
				setError(
					'address2',
					{
						type: 'focus',
						name: 'address2',
						message: formErrorMessage.address2Error,
					},
					{ shouldFocus: true }
				);
			}

			if (!data?.businessRegistrationId) {
				validCheck = false;
				setError(
					'businessRegistrationId',
					{
						type: 'focus',
						name: 'businessRegistrationId',
						message: formErrorMessage.businessRegistrationIdError,
					},
					{ shouldFocus: true }
				);
			}

			if (!isExistsImages) {
				validCheck = false;
				setError('businessRegistration', {
					name: 'businessRegistration',
					message: formErrorMessage.businessRegistrationError,
				});
			}
		} else {
			if (!data?.personalAddress) {
				validCheck = false;
				setError(
					'personalAddress',
					{
						type: 'focus',
						name: 'personalAddress',
						message: formErrorMessage.addressBasicError,
					},
					{ shouldFocus: true }
				);
			}

			if (!data?.personalAddressDetail) {
				validCheck = false;
				setError(
					'personalAddressDetail',
					{
						type: 'focus',
						name: 'personalAddressDetail',
						message: formErrorMessage.address2Error,
					},
					{ shouldFocus: true }
				);
			}
		}

		if (validCheck) {
			try {
				dispatch(openLoading());

				setSelectedOptions((prevState) => ({
					...prevState,
					userInformation: {
						...prevState.userInformation,
						userType: data?.userType,
						user: {
							...prevState.userInformation.user,
							name: data?.name,
							businessAddress: {
								...prevState.userInformation.user.businessAddress,
								zip: data?.zip,
								address1: data?.address1,
								address2: data?.address2,
							},
							businessRegistrationId: data?.businessRegistrationId,
							companyName: data?.companyName,
							personalAddress: data?.personalAddress,
							personalAddressDetail: data?.personalAddressDetail,
						},
					},
				}));
				dispatch(
					updateUserInformation({
						userType: data?.userType ?? selectedOptions?.userInformation?.userType,
						user: {
							name: data?.name ?? null,
							zip: data?.zip ?? null,
							businessAddress: {
								zip: data?.zip ?? null,
								address1: data?.address1 ?? null,
								address2: data?.address2 ?? null,
							},
							businessRegistrationId: data?.businessRegistrationId ?? null,
							companyName: data?.companyName ?? null,
							personalAddress: data?.personalAddress ?? null,
							personalAddressDetail: data?.personalAddressDetail ?? null,
						},
					})
				);

				let resultUploadData = '';
				let uploadComplateFlag = false;

				// 이미 사업자 등록증 업로드가 완료되었다면
				if (BookingOption?.userInformation?.user?.businessRegistration?.length >= 1) {
					uploadComplateFlag = true;
				}
				// 존재하지 않는다면?
				else {
					resultUploadData = await onFileUpload();
					setSelectedOptions((prevState) => ({
						...prevState,
						userInformation: {
							...prevState.userInformation,
							user: {
								...prevState.userInformation.user,
								businessRegistration: resultUploadData.url,
							},
						},
					}));
					dispatch(updateBusinessLicenseImage(resultUploadData.url));
				}

				if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
				} else {
					// // 1. 기존 데이터 취득하여 어디까지 왔는지 확인
					let reqData = getEstimateCookieData();
					let plainData = decodeURIComponent(atob(reqData));
					let infoResult = await getEstimateHistoryInfo(base64EncodeUnicode(plainData), 1);
					if (infoResult.Item.ResultCode === 200) {
						let resultJson = infoResult.ItemArray[0];
						// page가 4 이상이라면 미발송
						if (Number(resultJson.EstimatePage) >= PAGE.PAGE_06) {
							// No Action
						}
						// 3 이하라면 발송
						else {
							let info = getEstimateCookieDataAndDecrypt();
							await SendEztoc2({
								eztocType: 'contract',
								UserName: info?.UserName,
								UserPhone: info?.PhoneNumber,
							});
						}
					}
				}

				// await하지 않고, 모시러 서버에 저장
				SetMmEstimateHistory({
					...getEstimateCookieDataAndDecrypt(),
					EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
					EstimatePage: PAGE.PAGE_06,
					JsonData: JSON.stringify({
						...selectedOptions,
						userInformation: {
							...selectedOptions.userInformation,
							userType: data?.userType,
							user: {
								...selectedOptions.userInformation.user,
								name: data?.name,
								businessAddress: {
									...selectedOptions.userInformation.user.businessAddress,
									zip: data?.zip,
									address1: data?.address1,
									address2: data?.address2,
								},
								businessRegistrationId: data?.businessRegistrationId,
								companyName: data?.companyName,
								personalAddress: data?.personalAddress,
								personalAddressDetail: data?.personalAddressDetail,
								businessRegistration: uploadComplateFlag === true ? BookingOption?.userInformation?.user?.businessRegistration : resultUploadData.url,
							},
						},
					}),
				});

				/*
				let form = qs.stringify({
					"1. 계약자의 성함을 알려주세요.": obj.ContractName,
					"2. 계약자의 상세 주소를 알려주세요.": obj.ContractAddress + ' ' + obj.ContractAddress2,
					"3. 차량의 모델명을 알려주세요": obj.CarModel,
					"4. 이용 시작일은 언제 부터 인가요?  ": `${moment(obj.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(obj.ContractStartDate).format('ddd'))})`,
					"5. 원하시는 계약 기간은 어떻게 되시나요? ": obj.ContractMonthString,
					"6. 서비스 이용의 목적은 어떻게 되시나요?": obj.PurposeString === '기타' ? `기타(${obj.PurposeEtcStr})` : obj.PurposeString,
					"7. 담당자님의 성함을 알려주세요": obj.BookerName,
					"8. 담당자님의 연락처를 알려주세요": obj.BookerPhone,
					"9. 담당자님의 이메일 주소를 알려주세요": obj.BookerEmail,
					'10. 컨시어지 상담을 원하시면 알려주세요.': '웹견적서',
					'타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
					// "전화번호" : userinfo.UserPhone,
					// "이메일주소" : userinfo.UserEmail,
					"고객유형": obj.CustomerTypeString,
					"계약자 성함": obj.ContractName,
					"사업자등록번호": (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ContractBLNumber,
					"사업자 등록증": (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ComplateBusinessLicenseUrl,
					"계약요청상품": (obj.ContractReqProductString === undefined || obj.ContractReqProductString === null) ? '' : obj.ContractReqProductString
				});
				*/

				// userType  personal, corporate

				let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
				let SelectedProduct = '';
				SelectedProduct += getValueByTarget(BookingProducts, BookingOption.subscription, 'title') + ' ';
				SelectedProduct +=
					BookingOption.subscription === 'annual'
						? getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'title') +
						  ' ' +
						  getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'subtitle') +
						  ' ' +
						  getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'hourPrice') +
						  '원'
						: getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'title') +
						  ' ' +
						  getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'subtitle') +
						  ' ' +
						  getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'hourPrice') +
						  '원';

				const SubscriptionType = BookingOption?.subscription;
				let obj = {
					ContractName: data?.name,
					Address: data?.userType === 'personal' ? data?.personalAddress + ' ' + data?.personalAddressDetail : data?.address1 + ' ' + data?.address2,
					CarInfo: '', // 추가필요
					UsageDate: moment(BookingOption?.contractTerms1?.schedule).format('yyyy-MM-DD'),
					ContractMonth: SubscriptionType === 'annual' ? '' : getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'title'),
					NeedsString: getValueByTarget(PurposeOfContractOptions, data?.purposeOfContract, 'title'), // 탑승자
					BookerName: userInfoDecrypt.UserName,
					BookerPhoneNumber: userInfoDecrypt.PhoneNumber,
					BookerEmailAddress: userInfoDecrypt.EmailAddress,
					// new 웹견적서
					UserType: data?.userType === 'personal' ? '개인' : '법인',
					// 계약자 성함
					BusinessCode: data?.businessRegistrationId,
					BusinessLicense: uploadComplateFlag === true ? BookingOption?.userInformation?.user?.businessRegistration : resultUploadData.url,
					ContractProduct: SelectedProduct,
				};

				if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
				} else {
					setSheetContractResponse(obj);
					SendSlackContracting(obj);
				}

				// setSheetContractResponse
				dispatch(closeLoading());
			} catch (e) {
				dispatch(closeLoading());
			}

			if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
				navigate(URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT);
			} else {
				navigate(URLS.BOOKING_ESTIMATION_CONFIRM_CONTRACT);
			}

			// 결제 화면은 Todo
			// navigate(URLS.BOOKING_ESTIMATION_PAYMENT_METHOD)
		}
	});

	const handleAddressChange = (subField, value, target) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			userInformation: {
				...prevState.userInformation,
				user: {
					...prevState.userInformation.user,
					[target]: {
						...prevState.userInformation.user[target],
						[subField]: value,
					},
				},
			},
		}));
	};

	const handleAddressChange2 = (subField, value, target) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			userInformation: {
				...prevState.userInformation,
				user: {
					...prevState.userInformation.user,
					[target]: value,
				},
			},
		}));
	};

	const handleAddressChange3 = (subField, value, target) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			userInformation: {
				...prevState.userInformation,
				user: {
					...prevState.userInformation.user,
					businessAddress: {
						...prevState.userInformation.user.businessAddress,
						[target]: value,
					},
				},
			},
		}));
	};

	const handleImages = (e) => {
		if (e.target.files.length) {
			setFile(e.target.files[0].name);
			setFileManage(e.target.files[0]);
		} else {
			setFile('');
			setFileManage(null);
		}

		// const target = {
		// 	images: Array.from(e.target.files),
		// };
		// if (target) {

		// 	// setError('businessRegistration', null);
		// 	// setValue('businessRegistration', target)
		// }
	};

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setValue('date', date ? date.toISOString().split('T')[0] : ''); // ISO 포맷으로 날짜 저장
	};

	const RadioSet = ({ name, options }) => (
		<div className='input-set'>
			<div className='radio-set grid'>
				{options.map((option) => (
					<label key={option.value} className={classNames('radio', watch(name) === option.value && 'selected')}>
						<input type='radio' value={option.value} {...register(name)} />
						{option.label}
					</label>
				))}
			</div>
		</div>
	);

	const handleAddress = (e, e2, e3) => {};
	return (
		<>
			<div className={classNames('booking-wrap')}>
				<PageTitle title={'계약서 확인'} subtitle={'고객 유형 및 계약자 정보를 입력하세요'} />
				<form className={classNames('booking-form')} onSubmit={onSubmit}>
					<div className={classNames('booking-form-list')}>
						{!!UserTypeOptions && (
							<div className={'booking-form-item'}>
								<span className={classNames('title-mid')}>고객 유형</span>
								<RadioGroup classes={''} legend={'purposeOfContract'} selected={selectedOptions.userInformation.userType}>
									<ul className={classNames('booking-item-list row')}>
										{UserTypeOptions.map((item) => (
											<li key={item.title}>
												<Radio
													key={item.title}
													value={item.value}
													onChange={(e) => {
														setValue('userType', item.value);
														setUserType(item.value);
														setSelectedOptions((prevState) => ({
															...prevState,
															userInformation: {
																...prevState.userInformation,
																userType: item.value,
															},
														}));
													}}
												>
													<BookingItem selected={selectedOptions.userInformation.userType === item.value} subtitle={item?.title} isAlignCenter={true} />
												</Radio>
											</li>
										))}
									</ul>
								</RadioGroup>
							</div>
						)}

						<div className={'booking-form-item'}>
							<span className={classNames('title-mid')}>계약자 이름</span>
							<Input
								type={'text'}
								required
								setValue={setValue}
								error={errors.name}
								{...register('name')}
								onChange={(e) => {
									setValue('name', e.target.value);
									setSelectedOptions((prevState) => ({
										...prevState,
										userInformation: {
											...prevState.userInformation,
											user: {
												...prevState.userInformation.user,
												name: e.target.value,
											},
										},
									}));
								}}
								placeholder={'계약자 이름'}
							/>
						</div>

						{userType === 'personal' && (
							<>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>고객 자택 주소</span>
									<AddressInput
										register={register}
										setValue={setValue}
										zipError={errors.personalZip}
										address1Error={errors.personalAddress1}
										address2Error={errors.personalAddress2}
										zipRegister={'personalzip'}
										address1Register={'personalAddress1'}
										address2Register={'personalAddress2'}
										target={'personalAddress'}
										selectAddress={false}
										getValues={getValues}
										handleAddressChange={handleAddress}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>첫 이용일</span>
									<DatePickerInput selectedDate={selectedDate} onDateChange={handleDateChange} placeholder='--.--.--' />
									{errors.date && <span className='error-message'>{errors.date.message}</span>}
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>차량 모델</span>
									<Input
										type={'text'}
										required
										setValue={setValue}
										error={errors.carModel}
										{...register('carModel')}
										onChange={(e) => {
											setValue('carModel', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														carModel: e.target.value,
													},
												},
											}));
										}}
										placeholder={'차량 모델'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>차 번호</span>
									<Input
										type={'text'}
										required
										setValue={setValue}
										error={errors.calModel}
										{...register('carNumber')}
										onChange={(e) => {
											setValue('carNumber', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														carNumber: e.target.value,
													},
												},
											}));
										}}
										placeholder={'차 번호'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>드라이버 자차 주차 가능 여부</span>
									<RadioSet
										name='parkingAvailable'
										options={[
											{ value: '가능', label: '가능' },
											{ value: '불가능', label: '불가능' },
										]}
									/>
								</div>
							</>
						)}

						{userType === 'corporate' && (
							<>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>회사명</span>
									<Input
										type={'text'}
										required
										{...register('companyName')}
										setValue={setValue}
										error={errors.companyName}
										onChange={(e) => {
											setValue('companyName', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														companyName: e.target.value,
													},
												},
											}));
										}}
										placeholder={'회사명'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>사업자 등록증상의 주소</span>
									<AddressInput
										register={register}
										setValue={setValue}
										zipError={errors.companyZip}
										address1Error={errors.companyAddress1}
										address2Error={errors.companyAddress2}
										zipRegister={'companyZip'}
										address1Register={'companyAddress1'}
										address2Register={'companyAddress2'}
										target={'companyAddress'}
										selectAddress={false}
										getValues={getValues}
										handleAddressChange={handleAddress}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>사업자 등록번호</span>
									<Input
										type={'text'}
										required
										setValue={setValue}
										{...register('businessRegistrationId')}
										error={errors.businessRegistrationId}
										onChange={(e) => {
											setValue('businessRegistrationId', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														businessRegistrationId: e.target.value,
													},
												},
											}));
										}}
										placeholder={'사업자 등록번호'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>사업자 등록증 첨부</span>
									<div className={classNames('file-form-wrap')}>
										<div className={'file-form-set mobile-row'}>
											<div className={classNames('file-button-set')}>
												<input {...register('businessRegistration')} className='hidden' ref={inputRef} type='file' accept='image/*, application/pdf' onChange={handleImages} />
												{/* <input ref={inputRef} type='file' accept='image/*, application/pdf' onChange={handleImages} /> */}
												<Button
													styleType='icon'
													onClick={() => inputRef.current.click()}
													// disabled={isExistsImages}
												>
													<Icon icon={'attach'} />
													파일 첨부
												</Button>
											</div>
											{fileManage && <span>{fileManage?.name}</span>}
											{/* {isExistsImages && <span>파일 업로드가 완료되었습니다.</span>} */}
										</div>
										{errors.businessRegistration && !file && <span className={classNames('error')}>{errors.businessRegistration.message}</span>}
									</div>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>고객 자택 주소</span>
									<Input
										type={'text'}
										required
										setValue={setValue}
										{...register('customerAddress')}
										error={errors.customerAddress}
										onChange={(e) => {
											setValue('customerAddress', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														customerAddress: e.target.value,
													},
												},
											}));
										}}
										placeholder={'사업자 등록번호'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>첫 이용일</span>
									<DatePickerInput selectedDate={selectedDate} onDateChange={handleDateChange} placeholder='--.--.--' />
									{errors.date && <span className='error-message'>{errors.date.message}</span>}
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>차량 모델</span>
									<Input
										type={'text'}
										required
										setValue={setValue}
										error={errors.carModel}
										{...register('carModel')}
										onChange={(e) => {
											setValue('carModel', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														carModel: e.target.value,
													},
												},
											}));
										}}
										placeholder={'차량 모델'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>차 번호</span>
									<Input
										type={'text'}
										required
										setValue={setValue}
										error={errors.calModel}
										{...register('carNumber')}
										onChange={(e) => {
											setValue('carNumber', e.target.value);
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														carNumber: e.target.value,
													},
												},
											}));
										}}
										placeholder={'차 번호'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>드라이버 자차 주차 가능 여부</span>
									<RadioSet
										name='parkingAvailable'
										options={[
											{ value: '가능', label: '가능' },
											{ value: '불가능', label: '불가능' },
										]}
									/>
								</div>
							</>
						)}
					</div>
					<BookingFormSummary hasAmonut={false} description={description}>
						<div className={classNames('button-set')}>
							<Button onClick={() => navigate(URLS.BOOKING_ESTIMATION_CONFIRM_ESTIMATE)}>이전</Button>
							<Button styleType={'secondary'} type={'submit'}>
								다음
							</Button>
						</div>
					</BookingFormSummary>
				</form>
			</div>
		</>
	);
};

export default UserInformation;
