import { useState } from 'react';
import '../../../styles/comparisonEstimate.css';
import { COMPARISON_ESTIMATE_OPTIONS_INFO, COMPARISON_ESTIMATE_OPTION_LIST } from '../../../utils/constant';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import Header from '../../molecules/Header';
import classNames from 'classnames';
import useCheckPC from '../../../hooks/useCheckPC';
import { updateSubscription, updateUsageTime } from '../../../reducers/bookingEstimation';
import { useDispatch } from 'react-redux';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';

const ComparisonEstimate = ({ setOpenComparisionEstimate }) => {
	const { isPC } = useCheckPC();
	const dispatch = useDispatch();
	
	const { selectedOptions, setSelectedOptions } = useBookingOptionContext();

	const [selectedOption1, setSelectedOption1] = useState(COMPARISON_ESTIMATE_OPTION_LIST[0]);
	const [selectedOption2, setSelectedOption2] = useState(COMPARISON_ESTIMATE_OPTION_LIST[1]);
	const [selectedOption3, setSelectedOption3] = useState(COMPARISON_ESTIMATE_OPTION_LIST[1]);

	const selectedOptionInfo1 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption1);
	const selectedOptionInfo2 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption2);
	const selectedOptionInfo3 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption3);

	const HandleSelectedProduct = (subscription, usageTime) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			subscription,
			usageTime,
		}));
		dispatch(updateSubscription(subscription))
		dispatch(updateUsageTime(usageTime))
		setOpenComparisionEstimate(false)

		// 구글 시트쪽에 로우 추가 필요(?)
	}

	return (
		<>
			{isPC && <Header headerTitle={'비교견적'} onClickCloseBtn={() => setOpenComparisionEstimate(false)} />}
			<div className='comparison-estimate-wrap'>
				<div className='comparison-estimate'>
					<div className='estimate-item'>
						<div className='item-content'>
							<Select value={selectedOption1} selectedOption={selectedOption1} options={COMPARISON_ESTIMATE_OPTION_LIST} onOptionChange={(value) => setSelectedOption1(value)} />
							{selectedOptionInfo1 && (
								<>
									{selectedOptionInfo1.info.map((i) => (
										<div className={classNames('info-item-set', i.label === '총액' && 'sum')}>
											<span className='info-title'>{i.label}</span>
											<span className='info-value'>{i.value}</span>
										</div>
									))}
								</>
							)}
						</div>
						<Button styleType='secondary' onClick={()=>HandleSelectedProduct(selectedOptionInfo1.subscription, selectedOptionInfo1.usageTime)}>견적확인</Button>
					</div>
					<div className='estimate-item'>
						<div className='item-content'>
							<Select value={selectedOption2} selectedOption={selectedOption2} options={COMPARISON_ESTIMATE_OPTION_LIST} onOptionChange={(value) => setSelectedOption2(value)} />
							{selectedOptionInfo2 && (
								<>
									{selectedOptionInfo2.info.map((i) => (
										<div className={classNames('info-item-set', i.label === '총액' && 'sum')}>
											<span className='info-title'>{i.label}</span>
											<span className='info-value'>{i.value}</span>
										</div>
									))}
								</>
							)}
						</div>
						<Button styleType='secondary' onClick={()=>HandleSelectedProduct(selectedOptionInfo2.subscription, selectedOptionInfo2.usageTime)}>견적확인</Button>
					</div>
					<div className='estimate-item pc'>
						<div className='item-content'>
							<Select value={selectedOption3} selectedOption={selectedOption3} options={COMPARISON_ESTIMATE_OPTION_LIST} onOptionChange={(value) => setSelectedOption3(value)} />
							{selectedOptionInfo3 && (
								<>
									{selectedOptionInfo3.info.map((i) => (
										<div className={classNames('info-item-set', i.label === '총액' && 'sum')}>
											<span className='info-title'>{i.label}</span>
											<span className='info-value'>{i.value}</span>
										</div>
									))}
								</>
							)}
						</div>
						<Button styleType='secondary' onClick={()=>HandleSelectedProduct(selectedOptionInfo3.subscription, selectedOptionInfo3.usageTime)}>견적확인</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ComparisonEstimate;
