import React from 'react';
import classNames from 'classnames';
import Input from '../atoms/Input';
import Button from '../atoms/Button';

const AddressInput = ({ register, setValue, zipRegister, address1Register, address2Register, zipError, address1Error, address2Error, handleAddressChange, target, selectAddress = false }) => {
	return (
		<div className={classNames('address-input')}>
			<div className={classNames('input-wrap', selectAddress && 'select-address')}>
				<Input
					type={'text'}
					required
					error={zipError}
					onChange={(e) => {
						setValue(zipRegister, e.target.value);
						handleAddressChange('zip', e.target.value, target);
					}}
					placeholder={'우편번호'}
					{...register(zipRegister)}
				/>
				<Button type={'button'} styleType={'tertiary'} classes={'button-small'}>
					주소검색
				</Button>
				{selectAddress && (
					<Button type={'button'} styleType={'tertiary'} classes={'button-small'}>
						불러오기
					</Button>
				)}
			</div>
			<Input
				type={'text'}
				required
				error={address1Error}
				onChange={(e) => {
					setValue(address1Register, e.target.value);
					handleAddressChange('address1', e.target.value, target);
				}}
				placeholder={'기본주소'}
				{...register(address1Register)}
			/>
			<Input
				type={'text'}
				required
				error={address2Error}
				onChange={(e) => {
					setValue(address2Register, e.target.value);
					handleAddressChange('address2', e.target.value, target);
				}}
				placeholder={'상세주소'}
				{...register(address2Register)}
			/>
		</div>
	);
};

export default AddressInput;
