import { transFormWon } from "./numbers";

export const ESTIMATE_TYPE_MONTHLY_MOSILER = 1; // 견적서 타입 : 월간모시러
export const ESTIMATE_TYPE_DRIVER_DISPATCH = 2; // 견적서 타입 : 수행기사 파견

export const PAGE = {
	PAGE_01: 1,
	PAGE_02: 2,
	PAGE_03: 3,
	PAGE_04: 4,
	PAGE_05: 5,
	PAGE_06: 6,
	PAGE_07: 7,
	PAGE_08: 8,
	PAGE_09: 9,
	PAGE_10: 10,
	PAGE_11: 11,
	PAGE_12: 12,
	PAGE_13: 13,
	PAGE_14: 14,
	PAGE_15: 15,
	PAGE_16: 16,
	PAGE_17: 17,
	PAGE_18: 18,
	PAGE_19: 19,
	PAGE_20: 20,
};

export const KAKAO_CLIENT_ID = '9c8644cebd4d4307dd91d7678b7f66ca';

export const MAIN_REVIEW_SAMPLE_1 = {
	title: '“운전 뿐만 아니라<br>단순 업무도 대행해줘요”',
	reviewer: 'OOO 고객님 | VC 대표',
	contents: '출퇴근으로만 사용하던 수행기사 서비스를 공사 구분 없이 각종 단순업무를 대행해주니 일에만 더 집중할 수 있네요.',
	pattern: [
		{
			time: '07:00 AM',
			title: '조찬 미팅',
		},
		{ time: '09:00 AM', title: '광고 촬영현장 방문' },
		{
			time: '10:00 AM',
			title: 'Staff 간식 심부름',
		},
		{ time: '12:00 PM', title: '점심 약속' },
		{ time: '18:00 PM', title: '퇴근 운행' },
	],
};

export const MAIN_REVIEW_SAMPLE_2 = {
	title: '“개인 스케줄까지!<br>해결사 같은 서비스에요”',
	reviewer: 'OOO 고객님 | 스타트업 대표',
	contents: '아내의 심부름과 아이들을 돌봐줄 집사, 부모님 병원 동행에 주말에는 제 개인 스케줄까지 모든 걸 한 번에 해결해 준 서비스에요.',
	pattern: [
		{
			time: '07:00 AM',
			title: '출근 운행',
		},
		{ time: '08:00 AM', title: '채드윅 등교' },
		{
			time: '11:00 AM',
			title: '백화점 쇼핑 동행',
		},
		{ time: '14:00 PM', title: '간식 픽업' },
		{ time: '15:00 PM', title: '아이 하교/등원' },
		{ time: '17:00 PM', title: '퇴근 운행' },
	],
};

export const MAIN_QUESTION_LIST = [
	{
		title: '사용 시간이 추가될 경우 <br>요금은 어떻게 되나요?',
		contents: '계약한 월간 모시러 시간당 단가로 추가 사용 시간만큼 다음달 요금에 청구 드리고 있습니다.',
	},
	{
		title: '사용 못한 시간은 다음 달로 <br>이월되나요?',
		contents: '이월 가능합니다. 장기 계약 시 계약 기간 내에서 잔여시간을 이월시킬 수 있습니다.',
	},
	{
		title: '당일 사용도 가능한가요?',
		contents: '예약제로 진행되어 사용 전날 15시까지 알려주셔야 서비스 제공이 가능합니다. 하지만 무제한 상품의 경우 당일 사용이 가능합니다.',
	},
	{
		title: '시간을 나눠서 사용 <br>가능한가요?',
		contents: '운행 시작 시간을 기준으로 연속 사용하는 상품으로 나눠서 사용하실 경우 요금 조정/인상이 될 수 있습니다.',
	},
	{
		title: '파트너는 교체가 <br>가능한가요?',
		contents: '어떤 부분이든 원하시는 내용만 알려주세요. 요청사항에 부합하는 새로운 파트너로 교체가 가능합니다.',
	},

	{
		title: '운전만 제공되나요?',
		contents: '운전 뿐만 아니라 서류 발급 대행, 물품 구매 대행, 차량 정비 대행(정기 점검 등), 쇼핑 동행을 요청할 수 있으며, 집사 업무 범위는 계속 서비스 확장을 진행하고 있습니다.',
	},
];

export const QNA_SEARCH_KEYWORD_LIST = ['이용', '예약', '보험', '서비스', '드라이버'];
export const QNA_LIST = [
	{
		question: '출퇴근 및 등하교 서비스도 가능한가요?',
		answer: '출퇴근 및 등하교 운행은 <br>현재 구독상품으로 제공드리기 어려우나<br>한시적으로 진행해볼 예정입니다.<br>아래 링크를 통해 신청해 주시면, <br>순차적으로 답변을 드리고 있습니다.<br><br><a href="https://forms.gle/tFJ2AdhSvs9adtYL7" target="_blank">[출퇴근 및 등하교 서비스 이용 신청하기]</a>',
		keyword: '이용'
	},
	{
		question: '최소 이용 시간 변경 가능한가요??',
		answer: '월간 상품 중 최소시간 조정가능한 상품은<br>다음과 같습니다.<br>1. 평일 20일(160시간) 상품<br>2. 30일(240시간) 상품<br>해당 상품은 일일 최소 이용 시간 4시간으로 <br>조정이 가능합니다.<br>단, 이용을 못하신 시간은 이월되지 않습니다.',
		keyword: '이용'
	},
	{
		question: '언제부터 이용할 수 있나요?',
		answer: '계약 완료일로부터 1주일 뒤 이용이 가능하나,<br>좀 더 빠른 이용을 원하시는 고객님은 <br>모시러 컨시어지로 문의 주시면 가능여부 확인해<br>이용을 도와드리고 있습니다.',
		keyword: '이용'
	},

	{
		question: '연장은 언제까지 가능한가요?',
		answer: '기본 2시간까지 연장이 가능하며, 추가로 연장이<br>더 필요한 고객님은 현장에서 드라이버와 <br>소통해 주시면 됩니다.<br>현장에서 소통시 불가할 수 있으니, 사전 예약시<br>전달사항에 연장가능성에 대해 언급해주시면<br>연장 가능한 드라이버로 매칭 가능합니다.',
		keyword: '이용'
	},
	{
		question: '이용기간 중 일시 중지가 가능한가요?',
		answer: '이용 기간 중 일시 정지는 불가하오나, <br>부득이 필요한 경우 컨시어지로 연락을 주시면<br>담당자가 안내를 도와드립니다.',
		keyword: '이용'
	},
	{
		question: '컨시어지 운영시간은 어떻게 되나요?',
		answer: '- 운영시간 : 평일 09:00~17:00이며,<br>컨시어지 운영 시간 외 문의는 모시러 카카톡에 <br>남겨주시면 운영시간 내 순차적으로 답변을 드립니다.<br><br>- 연락처 : 1522-4556<br>- <a href="http://pf.kakao.com/_uxmjlM/chat" target="_blank">[모시러 카카오톡]</a><br>',
		keyword: '이용'
	},


	{
		question: '상품 초과 시간 결제는 어떻게 해야 하나요?',
		answer: '계약 기간 내 상품 이용 시간을 모두 소진하여도 추가 이용이<br>가능합니다. 초과 시간 결제는 각 상품 별 시간당 단가로 적용되며<br>후청구액으로 별도 안내를 드립니다.<br><br>예시) <br>*월간 80시간 상품*<br>- 최소 이용 시간 : 8시간<br>- 시간당 단가 : 28,600원<br><br>80시간을 초과하실 경우 시간당 추가금액 28,600원으로<br>이용이 가능합니다.  ',
		keyword: '이용'
	},
	{
		question: '할증 요금은 어떻게 되나요?',
		answer: '심야할증 요금과 편도 할증 요금이 있습니다.<br>해당 할증 요금은 후청구 금액으로 청구드리고 있습니다.<br><br>1. 심야 할증 요금<br>23~06사이에 시작/종료시<br>각각 20,000원 <br><br>2. 편도 할증 요금<br>왕복 운행의 경우 지역 할증 면제를 도와드리며, <br>편도 운행은 각 도별로 차등 적용 됩니다.<br><br><a href="http://mosiler.com/payment/areafee.html" target="_blank">[편도 할증 요금표]</a> <br><br><br>*지역할증은 서울을 기준으로 하며, 타지역에서 <br>이용하시는 경우 컨시어지로 문의 부탁드립니다.<br><br>*왕복운행의 경우 어느 지역을 경유하셔도 <br>지역할증은 추가되지 않습니다.',
		keyword: '이용'
	},
	{
		question: '취소 수수료는 어떻게 되나요?',
		answer: '드라이버 매칭이 완료된 운행을 기준으로 <br><br>- 이틀 전 : 없음<br>- 전일 : 1시간 차감<br>- 당일(운행 시작 전) : 예약 시간 50% 차감<br>- 당일(운행 시작 후) : 예약 시간 100% 차감<br><br>위와 같이 적용됩니다.',
		keyword: '이용'
	},
	{
		question: '결제 수단은 무엇으로 가능한가요?',
		answer: '모시러 서비스는 원하시는 결제 수단 모두 가능합니다.<br><br>- 카드 결제(법인카드 가능)<br>- 법인 이체(계산서 발행)<br>- 개인 이체(현금영수증 발급)',
		keyword: '이용'
	},
	{
		question: '결제 외 드라이버 부대비용은 없나요?',
		answer: '드라이버 보험료, 식대등 관리 비용 일체가 포함되어 있습니다.',
		keyword: '이용'
	},
	{
		question: '상품 이용 시간을 모두 사용하지 못하면 환불 되나요?',
		answer: '구독 서비스는 할인된 가격으로 기간 내 이용하는 상품으로<br>기간 내 이용하지 못한 시간은 자동 소멸처리 됩니다.<br><br>*단, 특별한 사유로 인하여 시간 사용이 되지 못한 부분은 <br>일부 이월을 도와드리고 있으니, 컨시어지로 문의 부탁드립니다.',
		keyword: '이용'
	},
	{
		question: '운행 중 대기 시간도 결제가 되나요?',
		answer: '예약하신 시작 시간부터 종료 시간까지 모두 운행 시간으로 간주되며,<br>대기 시간도 이에 포함됩니다. 운행 대기 시간 중 세차 및 정비 등<br>차량관리를 요청하실 수 있습니다. ',
		keyword: '이용'
	},

	{
		question: '계약을 진행하려면 어떻게 해야 하나요?',
		answer: '우선 상품의 견적을 확인해 주신 후<br>이용 가능한 파트너 프로필까지 확인해 주세요<br>파트너도 선택을 해주셨다면,<br>계약서 초안 작성을 부탁드립니다.<br>작성이 완료되면<br>컨시어지에서 응대 드리도록 하겠습니다.',
		keyword: '이용'
	},
	{
		question: '계약 갱신은 어떻게 진행되나요?',
		answer: '월간 구독상품은 별도의 서비스 이용 중단 요청이 <br>없는 경우 갱신일 3일 전 결제 후 자동 갱신되며,<br>연간 구독 상품은 시간 조기 소진 및 종료 기간 임박으로<br>사전 안내를 드려 확인하실 수 있도록 도와드립니다.',
		keyword: '이용'
	},
	{
		question: '계약을 중도해지할 경우 어떻게 되나요?',
		answer: '이용중 중도 해지할 경우는 다음과 같습니다.<br><br>- 중도해지수수료 : 30%<br>- 이용시간 : 비할인 금액으로 환산<br>- 환불금액 : 상품금액 - 중도해지수수료 - 이용시간<br>',
		keyword: '이용'
	},

	{
		question: '서비스 예약은 어떻게 하나요?',
		answer: '계약이 완료되면 구독 계정 가입 안내를 <br>드립니다. 가입 후 앱에서 일정을<br>예약하실 수 있습니다.',
		keyword: '예약'
	},
	{
		question: '1박 이상 출장 운행도 가능한가요?',
		answer: '가능합니다. 1박이상 예약은 컨시어지를 통해<br>진행되며, 요금은 아래와 같습니다.<br><br>- 이용시간 적용 : <br>1일 기본 12시간 차감 <br>(12시간 이상 운행 시 실 이용시간으로 적용)<br>- 출장비 : 1박 당 최대 150,000원<br>*출장비는 숙박비, 식비 등 제반비용이 포함되어<br>있으며, 직접 제공 시에는 출장비 금액이<br>조정될 수 있습니다.<br>*숙박 지역, 성수기 등 실제 상황에 따라 <br>출장비는 변동될 수 있습니다. ',
		keyword: '예약'
	},
	{
		question: '당일 운행은 어떻게 예약하나요?',
		answer: '모시러 서비스는 전일 예약제로 당일 예약은<br>불가합니다. 전일 오후 3시 이전 예약을 주시면<br>100% 매칭이 가능하오니 안내 된 시간 내<br>예약을 부탁드립니다.',
		keyword: '예약'
	},
	{
		question: '경유지 변경이 가능한가요?',
		answer: '예약 시 등록해 주신 경유지는 현장에서 드라이버에게<br>요청하시어 변경하실 수 있습니다. <br><br>단, 갑작스러운 1박 이상의 운행 또는 장거리 운행은<br>변경이 어려우니, 사전에 스케줄을 미리 확인하시어 <br>컨시어지로 전달 부탁드립니다.',
		keyword: '예약'
	},
	{
		question: '운행시간 변경이 가능한가요?',
		answer: '드라이버 매칭이 완료된 예약 운행은 시간 변경 시 <br>드라이버의 가능 여부를 확인하여 안내드립니다. <br><br>매칭 된 드라이버가 불가할 경우 재섭외가 진행되며,<br>예약 시간이 지난 시점에는 매칭이 어려울 수 있습니다.',
		keyword: '예약'
	},
	{
		question: '시작/종료 장소를 변경할 수 있나요?',
		answer: '드라이버 매칭 전 변경 요청은 언제든지 가능하며, <br>매칭이 완료 된 운행은 <br>드라이버 가능 여부를 확인하여 안내드립니다. <br><br>최초 예약 시 장소와 거리 차이가 있을 경우 <br>드라이버 매칭이 취소 될 수 있으며, <br>재섭외 후 안내드립니다.',
		keyword: '예약'
	},


	{
		question: '보험사는 어디인가요?',
		answer: '모시러 드라이버는 모두 현대해상으로 <br>가입이 완료되어 있습니다.<br><br>보험 보장내용은 아래와 같습니다.<br><br>- 보험사 : 현대해상<br>- 대인배상 : 무한<br>- 대물배상 : 1억원<br>- 자기신체사고 : 사망후유장애 3천만원, 부상 1.5천만원 (피해자 1인당)<br>- 자기차량손해 : 5천만원',
		keyword: '보험'
	},
	{
		question: '보험이 적용되지 않는 상황도 있나요?',
		answer: '드라이버의 과실이 인정되지 않은 사고나<br>사고발생 후 2주 이상 경과된 건은<br>보험 적용이 불가합니다.',
		keyword: '보험'
	},

	{
		question: '차량 등록 가능 수가 정해져 있나요?',
		answer: '2대까지 등록이 가능하며, 추가 등록이 <br>필요한 경우 컨시어지로 문의 부탁드립니다.',
		keyword: '보험'
	},
	{
		question: '차량이 같이 제공 되나요?',
		answer: '모시러 서비스는 수행기사만 제공되는 <br>서비스 입니다.<br>차량 포함 서비스를 원하실 경우 <br>컨시어지로 별도 문의를 부탁드립니다.',
		keyword: '보험'
	},
	{
		question: '특장 차량의 기준은 무엇인가?',
		answer: '신차 가액 2억원 이상, 기본 프레임을 <br>개조한 차량으로 분류됩니다.<br><br>예시) 카니발하이리무진 차량과 같이 <br>천장을 개조하여 변형된 차량 ',
		keyword: '보험'
	},

	{
		question: '사고 처리 프로세스는 어떻게 되나요?',
		answer: '사고 시 고객님의 안전을 최우선 확인하여 현장 조치가 이루어지며,<br>차량 파손의 경우 당사의 보험으로 보호 받으실 수 있습니다. <br><br>[사고 처리 과정]<br>① 현장 확인<br>② 보험 접수 <br>③ 당사 협력 공업사 입고<br>④ 수리 과정 공유<br>⑤ 원하시는 위치로 탁송',
		keyword: '보험'
	},
	{
		question: '보험 보장 범위는 어떻게 되나요?',
		answer: '- 보험사 : 현대해상<br>- 대인배상 : 무한<br>- 대물배상 : 1억원<br>- 자기신체사고 : 사망후유장애 3천만원, 부상 1.5천만원 (피해자 1인당)<br>- 자기차량손해 : 5천만원',
		keyword: '보험'
	},
	{
		question: '사고 차량 입고 후 대차는 가능한가요?',
		answer: '차량 수리일정 기준 국내 3000cc 세단으로 제공됩니다.',
		keyword: '보험'
	},
	{
		question: '원하는 공업사로 입고가 가능한가요?',
		answer: '차량 수리는 당사의 협력 공업사를 통해 가능합니다. <br>[협력 공업사 정보]<br>1. 지성모터스 <br>- 주소 : 서울 강남구 영동대로115길 4<br>- 연락처 : 02-545-0160<br><br>2. 뉴프라임모터스<br>- 주소 : 서울 성동구 연무장19길 12<br>- 연락처 : 02-3409-8255',
		keyword: '보험'
	},

	{
		question: '일반 상품과 구독 상품 차이점?',
		answer: '1. 매칭 보장<br>- 일반 예약은 가능한 드라이버가 없을 경우 매칭이 불가할 수 있지만<br>구독 상품의 경우 전일 15시까지 예약하시면 100% 매칭을 보장드립니다.<br><br>2. 드라이버 풀<br>- 일반 예약은 어떤 드라이버가 매칭이 되는지 알기 어렵지만,<br>구독 상품은 드라이버 풀을 1~3명 구성하여 안정적인 이용을 도와드립니다.<br><br>3. 드라이버 교체 <br>- 매칭된 드라이버의 서비스가 만족스럽지 못할 경우 컨시어지로 <br>문의 주시면 즉시 교체를 도와드립니다.',
		keyword: '서비스'
	},
	{
		question: '사택기사란 무엇인가요?',
		answer: '일반 비지니스 목적 외 가족 또는 지인의 운행과 운전 외 <br>서비스가 필요한 분들에게 적용됩니다. <br><br>운전 외 서비스 예)<br>- 가족, 지인 에스코트<br>- 운전 외 심부름',
		keyword: '서비스'
	},
	{
		question: '서비스 범위는 어디까지 가능한가요?',
		answer: '기본적으로 운전과 관련된 서비스가 제공됩니다.<br>- 운전 수행, 도어 서비스, 짐 서비스, 에스코트<br><br>그 외 서류 전달, 차량 이동 등 간단한 심부름이 제공됩니다.<br><br>*과도한 가사 업무나 운전과 관련 없는 서비스 요청은 <br>거절될 수 있습니다.',
		keyword: '서비스'
	},
	{
		question: '모시러에서 드라이버 관리 방법은?',
		answer: '모시러 드라이버는 3시간의 의무 교육(의전 및 드라이빙 테스트)을 <br>완료한 뒤 운전경력증명서와 범죄이력조회를 통하여 검증 후 <br>배정이 되고 있습니다.',
		keyword: '서비스'
	},
	{
		question: '월간 모시러의 장점이 뭔가요?',
		answer: '채용과 같은 조건으로 이용하시면서 <br>드라이버 관리 비용(4대보험, 주휴수당, 퇴직금, 식비, 주 52시간 이상 근무 등)을 <br>신경쓰지 않고 원하는 날짜와 시간에 선택하여 이용할 수 있습니다.',
		keyword: '서비스'
	},
	{
		question: '서비스 지역은 어떻게 되나요?',
		answer: '출발지 기준으로 서울, 경기, 인천, 부산에서 제공되고 있으며,  <br>서비스 가능 지역을 전국으로 넓혀가고 있습니다. <br>이용 원하시는 지역을 말씀해 주시면 <br>자세한 안내를 도와드리겠습니다.<br><br>신청링크삽입',
		keyword: '서비스'
	},
	{
		question: '보안 관리는 어떻게 되나요?',
		answer: '모시러의 모든 드라이버는 교육 이수 후 <br>고객 비밀유지 서약서를 작성하고 있습니다. <br>현장에서 보고 들은 내용을 포함하여 서비스 제공 중 <br>취득한 모든 정보는 비밀유지서약에 포함되고 있습니다.',
		keyword: '서비스'
	},
	
	
	{
		question: '선호하는 드라이버로 매칭이 가능한가요?',
		answer: '계약완료 후 선호하는 연령, 성별, 주행스타일 등을 확인하는<br>기본 정보 요청서를 안내드립니다. <br>정보를 작성하여 주시면 드라이버 섭외에 반영되고 있습니다.',
		keyword: '드라이버'
	},
	{
		question: '드라이버는 1인으로 제공되나요?',
		answer: '모시러 드라이버는 프리랜서로 고객님께 배정된<br>드라이버 중 스케줄과 성향이 가장 잘 맞는 드라이버가<br>대부분의 운행을 수행합니다. 서비스 이용을 자주 하실 수록 <br>1명의 드라이버가 제공 될 확률이 높아집니다.<br><br>*월간 상품은 기본적으로 1~3명 드라이버 풀이 제공됩니다.',
		keyword: '드라이버'
	},
	{
		question: '드라이버 교체가 가능한가요?',
		answer: '경험해 보신 드라이버의 교체가 필요한 경우 <br>언제든지 컨시어지로 말씀해 주시면 <br>교체를 도와드리고 있습니다. ',
		keyword: '드라이버'
	},
	{
		question: '드라이버 대기 장소를 제공해야 하나요?',
		answer: '드라이버는 기본적으로 고객님께서 요청하신 장소에서<br>대기가 이루어집니다. 대기 장소가 별도 없는 경우 <br>주변 카페 또는 별도 장소에서 대기하고 있습니다.',
		keyword: '드라이버'
	},
	{
		question: '드라이버 교육은 어떻게 진행되나요?',
		answer: '교육은 총 2~3시간 진행되며, 운전, 의전, 안전, 보안 교육을 <br>이수한 뒤 운행이 배정되고 있습니다. 추가로 요청하실 교육이 <br>있으실 경우 컨시어지로 말씀하여 주시면 <br>확인 후 추가 교육이 가능합니다.',
		keyword: '드라이버'
	},
	{
		question: '드라이버 식비를 제공해야 하나요?',
		answer: '모시러 서비스 이용료 내 식비는 포함되어 있습니다. <br>장시간 운행 시 약 20~30분 시간을 제공해 주시면 식사 후 복귀하여 <br>연락을 드리고 있습니다.',
		keyword: '드라이버'
	},

];

export const COMPARISON_ESTIMATE_OPTION_LIST = ['월간모시러 10일', '월간모시러 14일', '월간모시러 20일', '월간모시러 30일', '연간모시러 100시간', '연간모시러 200시간', '연간모시러 300시간'];

export const COMPARISON_ESTIMATE_OPTIONS_INFO = [
	{
		title: '월간모시러 10일',
		subscription: 'monthly',
		usageTime: 'day10',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '80시간',
			},
			{
				label: '드라이버 풀',
				value: '5명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: '26,000원',
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(26000*80),
			},
		],
	},
	{
		title: '월간모시러 14일',
		subscription: 'monthly',
		usageTime: 'day14',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '112시간',
			},
			{
				label: '드라이버 풀',
				value: '3명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(24000),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(24000 * 112),
			},
		],
	},
	{
		title: '월간모시러 20일',
		subscription: 'monthly',
		usageTime: 'day20',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '160시간',
			},
			{
				label: '드라이버 풀',
				value: '3명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(22500),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(22500 * 160),
			},
		],
	},
	{
		title: '월간모시러 30일',
		subscription: 'monthly',
		usageTime: 'day30',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '240시간',
			},
			{
				label: '드라이버 풀',
				value: '3명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(21000),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(21000 * 240),
			},
		],
	},
	{
		title: '연간모시러 100시간',
		subscription: 'annual',
		usageTime: 'annual_1year_100h',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '100시간',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(26400),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(26400 * 100),
			},
		],
	},
	{
		title: '연간모시러 200시간',
		subscription: 'annual',
		usageTime: 'annual_1year_200h',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '200시간',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(25500),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(25500 * 200),
			},
		],
	},
	{
		title: '연간모시러 300시간',
		subscription: 'annual',
		usageTime: 'annual_1year_300h',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '300시간',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(23700),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(23700 * 300),
			},
		],
	},
];


// '연간모시러 100시간', '연간모시러 200시간', '연간모시러 300시간'];