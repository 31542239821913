import '../../../styles/mainQuestion.css';

const MainQuestion = ({ title, contents }) => {
	return (
		<>
			<div className='main-question-wrap'>
				{title && (
					<span className={'title'}>
						Q.
						<span dangerouslySetInnerHTML={{ __html: title }}></span>
					</span>
				)}
				{contents && <span className='contents'>{contents}</span>}
			</div>
		</>
	);
};

export default MainQuestion;
