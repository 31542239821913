import { useState, useEffect } from 'react';
import '../../../styles/comparisonEstimate.css';
import { COMPARISON_ESTIMATE_OPTIONS_INFO, COMPARISON_ESTIMATE_OPTION_LIST } from '../../../utils/constant';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import Header from '../../molecules/Header';
import classNames from 'classnames';
import useCheckPC from '../../../hooks/useCheckPC';
import { updateSubscription, updateUsageTime } from '../../../reducers/bookingEstimation';
import { useDispatch } from 'react-redux';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import ProfileEstimate from '../DriverDispatchManage/ProfileEstimate';

const ComparisonPartnerProfileFrame = ({ setOpenPartnerProfile }) => {
	const { isPC } = useCheckPC();
	const dispatch = useDispatch();

	const { selectedOptions, setSelectedOptions } = useBookingOptionContext();

	const [selectedOption1, setSelectedOption1] = useState(COMPARISON_ESTIMATE_OPTION_LIST[0]);
	const [selectedOption2, setSelectedOption2] = useState(COMPARISON_ESTIMATE_OPTION_LIST[1]);
	const [selectedOption3, setSelectedOption3] = useState(COMPARISON_ESTIMATE_OPTION_LIST[1]);

	/** 프로필 데이터 **/
	const [profileInfo, setProfileInfo] = useState([]);

	const selectedOptionInfo1 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption1);
	const selectedOptionInfo2 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption2);
	const selectedOptionInfo3 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption3);

	const [selectedInfo, setSelectedInfo] = useState({});
	const [selectedIdxInfo, setSelectedIdxInfo] = useState(0);

	const HandleSelectedProduct = (subscription, usageTime) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			subscription,
			usageTime,
		}));
		dispatch(updateSubscription(subscription))
		dispatch(updateUsageTime(usageTime))
		setOpenPartnerProfile(false)

		// 구글 시트쪽에 로우 추가 필요(?)
	}

	useEffect(() => {
		const getProfileData = async () => {
			const obj = [
				{
					img: 'https://manage.mosiler.com/AttachFile/Profile//20240409094425789.jpg',
					DriverName: '박*철',
					FullName: '박성철',
					DriverNo: '9817',
					Age: '40대',
					GraphData: [90, 100, 85, 100, 90, 85],
					PersonalHistory: [
						
					],
					EducationHistory: [
						
					],
					CertificateHistory: [
						
					],
					Feedback: {
						Teacher: '양심규',
						Date: '2024년 03월 02일',
						Comment: '운전이 안정적 01'
					},
					Address: '',
					SmokingStatus: '',
					MarriedStatus: '',
					BabyStatus: '',
					Introduce: '',
				},
				{
					img: 'https://manage.mosiler.com/AttachFile/Profile//20240701163656311.jpg',
					DriverName: '김*성',
					FullName: '김민성',
					DriverNo: '10057',
					Age: '40대',
					GraphData: [90, 100, 90, 100, 90, 90],
					PersonalHistory: [
						
					],
					EducationHistory: [
						
					],
					CertificateHistory: [
						
					],
					Feedback: {
						Teacher: '양심규',
						Date: '2024년 03월 02일',
						Comment: '운전이 안정적 02'
					},
					Address: '',
					SmokingStatus: '',
					MarriedStatus: '',
					BabyStatus: '',
					Introduce: '',
				},
				{
					img: 'https://manage.mosiler.com/AttachFile/Profile//20240328144516873.jpg',
					DriverName: '정*곤',
					FullName: '정재곤',
					DriverNo: '9750',
					Age: '40대',
					GraphData: [80, 90, 95, 90, 90, 85],
					PersonalHistory: [
						// {
						// 	title: '장길동 운행',
						// 	startTime: '2024.01',
						// 	endTime: '2024.08',
						// },
						// {
						// 	title: '주식회사 장길동 의전 운전',
						// 	startTime: '2022.06',
						// 	endTime: '2023.12',
						// },
					],
					EducationHistory: [
						// {
						// 	title: '제주 XX 중학교 졸업',
						// 	startTime: '2024.01',
						// 	endTime: '2024.08',
						// },
						// {
						// 	title: '서울 XX 고등학교 졸업',
						// 	startTime: '2022.06',
						// 	endTime: '2023.12',
						// },
					],
					CertificateHistory: [
						// {
						// 	title: '정보처리기사 자격증 취득',
						// 	time: '2024.03',
						// },
						// {
						// 	title: '워드프로세서 1급 자격증 취득',
						// 	time: '2024.03',
						// }
					],
					Feedback: {
						Teacher: '양심규',
						Date: '2024년 03월 02일',
						Comment: '운전이 안정적 03'
					},
					Address: '',
					SmokingStatus: '',
					MarriedStatus: '',
					BabyStatus: '',
					Introduce: '',
				}
			]
			setProfileInfo(obj)
			setSelectedInfo(obj[0])
		}
		getProfileData();
	}, [])

	const styleBtn = {
		border: '2px solid #fff'
	}

	return (
		<>
			{<Header headerTitle={'파트너 프로필'} onClickCloseBtn={() => setOpenPartnerProfile(false)} />}
			{!isPC && <div style={{
				position: 'fixed',
				zIndex: 12,
				left: '50%',
				transform: 'translate(-50%, 20%)',
				display: 'flex',
				flexDirection: 'row',
				top: '50px',
				justifyContent: 'center',
				width: '300px',
				alignItems: 'center',
				gap: '5px'
				// top: '30px',
				// width: '100%',
			}}>
				{profileInfo.length >= 1 && <>
					{profileInfo.map((data, idx) => (
						<Button
							style={styleBtn}
							styleType={selectedIdxInfo === idx ? 'secondary' : 'primary'}
							classes={'fit-content button-small'}
							onClick={() => {
								setSelectedInfo(profileInfo[idx])
								setSelectedIdxInfo(idx)
							}}>{`추천 ${idx + 1}`}</Button>
					))}
				</>}
			</div>}

			<div className={`comparison-estimate-wrap`}>
				<div className='comparison-estimate'>
					{isPC ?
						<>
							{profileInfo.length >= 1 && <>
								{profileInfo.map((data, idx) => (
									<div className={`estimate-item-custom ${idx >= 2 && 'pc'}`} >
										<div className='item-content'>
											<ProfileEstimate selectedPartnerInfo={data} modalClose={()=>setOpenPartnerProfile(false)} />
										</div>
									</div>
								))}
							</>}
						</>
						:
						<>
							<div className={`estimate-item-custom`} >
								<div className='item-content'>
									<ProfileEstimate selectedPartnerInfo={selectedInfo} modalClose={()=>setOpenPartnerProfile(false)}/>
								</div>
							</div>
						</>
					}

				</div>
			</div>
		</>
	);
};

export default ComparisonPartnerProfileFrame;
